import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loginDisplay = false;


  constructor( @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private authService: MsalService,
  private msalBroadcastService: MsalBroadcastService,
  private router: Router,
  ) {
   }

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
      });
    
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      })
    
  }
  
  setLoginDisplay() : boolean{
     this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
     return this.loginDisplay
  }
  login() {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    
    } else {
      this.authService.loginRedirect();
    }
  }

  
  logout() { 
    this.authService.logoutRedirect({
      postLogoutRedirectUri: environment.redirectUri
    });
  }
  handleRedirectCallback(): void {
    this.authService.instance.handleRedirectPromise().then(() => {
      if (this.setLoginDisplay()) {
        this.router.navigate(['/home']); // Redirect to dashboard after successful login
      }
    }).catch((error: any) => {
      console.error('Redirect error:', error);
    });
  }
}
